<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewRoleSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-role-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Role
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="roleData.name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Permissions -->
          <validation-provider
            #default="validationContext"
            name="permissions"
            rules="required"
          >
            <b-form-group label="Permissions">
              <b-form-checkbox-group
                id="permissions"
                v-model="roleData.permissions"
                name="permissions"
                class="demo-inline-spacing"
                :state="roleData.permissions.length > 0"
                inline
              >
                <b-row
                  v-for="(argument, index) in permissions"
                  :key="index"
                >
                  <b-col
                    md="12"
                  >
                    <h5
                      v-if="index === 'filters'"
                      v-b-tooltip.hover.left.v-danger
                      title="Filters need list of families of filters"
                    >
                      {{ index.charAt(0).toUpperCase() + index.slice(1) }}
                    </h5>
                    <h5
                      v-else-if="index === 'galleries'"
                      v-b-tooltip.hover.left.v-danger
                      title="Galleries need list of items"
                    >
                      {{ index.charAt(0).toUpperCase() + index.slice(1) }}
                    </h5>
                    <h5 v-else>
                      {{ index.charAt(0).toUpperCase() + index.slice(1) }}
                    </h5>
                  </b-col>
                  <b-col>
                    <b-form-checkbox
                      v-for="permission in argument"
                      :key="permission.id"
                      :value="permission.id"
                    >
                      {{ permission.displayable_name.charAt(0).toUpperCase() + permission.displayable_name.slice(1) }}
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-form-invalid-feedback :state="roleData.permissions.length > 0">
                  Please select at least one permission
                </b-form-invalid-feedback>
              </b-form-checkbox-group>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckboxGroup,
  BFormCheckbox,
  BRow,
  BCol,
  VBTooltip,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {

  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormCheckboxGroup,
    BFormCheckbox,
    BRow,
    BCol,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  model: {
    prop: 'isAddNewRoleSidebarActive',
    event: 'update:is-add-new-role-sidebar-active',
  },
  props: {
    isAddNewRoleSidebarActive: {
      type: Boolean,
      required: true,
    },
    permissions: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  methods: {
    resolveOptionPermissions() {
      return this.permissions.map(data => ({ text: data.displayable_name, value: data.id }))
    },
  },
  setup(props, { emit }) {
    // Use toast
    const toast = useToast()

    const blankRoleData = {
      name: null,
      permissions: [],
    }

    const roleData = ref(JSON.parse(JSON.stringify(blankRoleData)))
    const resetuserData = () => {
      roleData.value = JSON.parse(JSON.stringify(blankRoleData))
    }

    const onSubmit = () => {
      store.dispatch('roles/addRole', roleData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-role-sidebar-active', false)
        }).catch(error => {
          console.log(error.response)
          Object.values(error.response.data)
            .forEach(message => {
              toast({
                component: ToastificationContent,
                props: {
                  title: message[0],
                  icon: 'AlertTriangleIcon',
                  variant: 'warning',
                },
              })
            })
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      roleData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
