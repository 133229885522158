<template>
  <b-card
    no-body
    class="mb-0 mt-1"
  >

    <role-list-add-new
      v-if="$can('store', 'Role') && Object.keys(getCurrentPermissions()).length > 0"
      :is-add-new-role-sidebar-active.sync="isAddNewRoleSidebarActive"
      :permissions="getCurrentPermissions()"
      @refetch-data="refetchData"
    />

    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Add Item -->
        <b-col
          cols="12"
          md="12"
        >
          <div class="d-flex align-items-right justify-content-end">
            <b-button
              v-if="$can('store', 'Role')"
              variant="primary"
              @click="isAddNewRoleSidebarActive = true"
            >
              <span class="text-nowrap">Add Role</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      ref="refRolesListTable"
      class="position-relative mh-200"
      :items="fetchRoles"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No items found"
      :sort-desc.sync="isSortDirDesc"
    >

      <template #row-details="row">
        <b-card>
          <b-row
            v-for="(permissions, index) in row.item.permissions_by_argument"
            :key="index"
            class="mb-2"
          >
            <b-col md="12">
              <h5>{{ index.charAt(0).toUpperCase() + index.slice(1) }}</h5>
            </b-col>
            <b-col
              v-for="permission in permissions"
              :key="permission.id"
              md="3"
              class="hoverborder"
            >
              <span class="d-block">{{ permission.displayable_name.charAt(0).toUpperCase() + permission.displayable_name.slice(1) }}</span>
            </b-col>
          </b-row>

          <b-button
            size="sm"
            variant="outline-secondary"
            @click="row.toggleDetails"
          >
            Hide Details
          </b-button>
        </b-card>
      </template>

      <!-- Column: name -->
      <template #cell(name)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.name }}</span>
        </div>
      </template>

      <!-- Column: users -->
      <template #cell(users)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.related_users }}</span>
        </div>
      </template>

      <template #cell(permissions)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.permissions.length }}</span>
        </div>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >

          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item @click="data.toggleDetails">
            <feather-icon icon="EyeIcon" />
            <span
              class="align-middle ml-50"
            >Details</span>
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('update', 'Role')"
            @click="$bvModal.show('modal-edit-role-'+data.item.id)"
          >
            <feather-icon icon="EditIcon" />
            <span
              class="align-middle ml-50"
            >Edit</span>
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('destroy', 'Role') && data.item.related_users === 0"
            ref="swalConfirmDeleteItem"
            @click="swalConfirmDeleteRole(data.item.id)"
          >
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
          <role-list-edit
            v-if="$can('update', 'Role') && Object.keys(getCurrentPermissions()).length > 0"
            :id="'modal-edit-role-'+data.item.id"
            :role-edit-data="data.item"
            :permissions="getCurrentPermissions()"
            @refetch-data="refetchData"
          />
        </b-dropdown>
      </template>

    </b-table>
  </b-card>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BDropdown,
  BDropdownItem,
  BButton,
  // BLink,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import store from '@/store'
import useRoleList from '@/views/staff/role/role-list/useRoleList'
import RoleListAddNew from '@/views/staff/role/role-list/RoleListAddNew.vue'
import { ref } from '@vue/composition-api'
import RoleListEdit from '@/views/staff/role/role-list/RoleListEdit.vue'

export default {
  name: 'Role',
  components: {
    RoleListEdit,
    RoleListAddNew,
    // NewItem,
    BCard,
    BRow,
    BCol,
    BTable,
    BDropdown,
    BDropdownItem,
    BButton,
  },
  directives: {
    Ripple,
  },
  setup() {
    const isAddNewRoleSidebarActive = ref(false)

    const {
      fetchRoles,
      fetchPermissions,
      getCurrentRoles,
      getCurrentPermissions,
      tableColumns,
      getByRole,
      getPermissions,
      refRolesListTable,
      sortBy,
      isSortDirDesc,
      refetchData,
    } = useRoleList()

    getByRole.value = true
    getPermissions.value = true

    fetchPermissions()

    return {
      isAddNewRoleSidebarActive,

      fetchRoles,
      fetchPermissions,
      getCurrentRoles,
      getCurrentPermissions,
      tableColumns,
      refRolesListTable,
      sortBy,
      isSortDirDesc,
      refetchData,
    }
  },
  methods: {
    swalConfirmDeleteRole(id) {
      this.$swal({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            store.dispatch('roles/deleteRole', id)
              .then(() => {
                this.refetchData()
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Role has been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              })
          } else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Cancelled',
              text: 'Role is safe :)',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
          return result.value
        })
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped/>
