<template>
  <!-- modal -->
  <b-modal
    :id="'modal-edit-role-'+roleEditData.id"
    :ref="'modal-edit-role-'+roleEditData.id"
    :title="'Role ' + roleEditData.name"
    ok-title="Update"
    cancel-variant="outline-secondary"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <validation-observer ref="editRoleForm">
      <form @submit.stop.prevent="validationForm">

        <!-- Name -->
        <validation-provider
          #default="validationContext"
          name="name"
          rules="required"
        >
          <b-form-group
            label="Name"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="roleData.name"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Permissions -->
        <validation-provider
          #default="validationContext"
          name="permissions"
          rules="required"
        >
          <b-form-group label="Permissions">
            <b-form-checkbox-group
              id="permissions"
              v-model="roleData.permissions"
              name="permissions"
              class="demo-inline-spacing"
              :state="roleData.permissions.length > 0"
              inline
            >
              <b-row
                v-for="(argument, index) in permissions"
                :key="index"
              >
                <b-col
                  md="12"
                >
                  <h5
                    v-if="index === 'filters'"
                    v-b-tooltip.hover.left.v-danger
                    title="Filters need list of families of filters"
                  >
                    {{ index.charAt(0).toUpperCase() + index.slice(1) }}
                  </h5>
                  <h5
                    v-else-if="index === 'galleries'"
                    v-b-tooltip.hover.left.v-danger
                    title="Galleries need list of items"
                  >
                    {{ index.charAt(0).toUpperCase() + index.slice(1) }}
                  </h5>
                  <h5 v-else>
                    {{ index.charAt(0).toUpperCase() + index.slice(1) }}
                  </h5>
                </b-col>
                <b-col>
                  <b-form-checkbox
                    v-for="permission in argument"
                    :key="permission.id"
                    :value="permission.id"
                  >
                    {{ permission.displayable_name.charAt(0).toUpperCase() + permission.displayable_name.slice(1) }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <b-form-invalid-feedback :state="roleData.permissions.length > 0">
                Please select at least one permission
              </b-form-invalid-feedback>
            </b-form-checkbox-group>

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

      </form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BFormInvalidFeedback,
  BFormCheckboxGroup,
  BFormCheckbox,
  BRow,
  BCol,
  VBTooltip,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BModal,
    BFormInvalidFeedback,
    BFormCheckboxGroup,
    BFormCheckbox,
    BRow,
    BCol,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    roleEditData: {
      type: Object,
      required: true,
    },
    permissions: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      name: '',
      nameState: null,
      last_nameState: null,
      emailState: null,
      roleState: null,
      statusState: null,
      passwordState: null,
      submittedNames: [],
    }
  },
  methods: {
    validationForm() {
      this.$refs.editRoleForm.validate().then(success => {
        if (success) {
          this.handleSubmit()
        }
      })
    },
    resetModal() {
      this.nameState = null
      this.last_nameState = null
      this.emailState = null
      this.roleState = null
      this.statusState = null
      this.passwordState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.validationForm()
    },
    handleSubmit() {
      this.onSubmit(this.roleEditData.id)

      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs[`modal-edit-role-${this.roleEditData.id}`].toggle('#toggle-btn')
      })
    },
  },
  setup(props, { emit }) {
    // Use toast
    const toast = useToast()

    const blankRoleData = {
      name: props.roleEditData.name,
      permissions: props.roleEditData.permissions.map(data => data.id),
    }

    const roleData = ref(JSON.parse(JSON.stringify(blankRoleData)))
    const resetroleData = () => {
      roleData.value = JSON.parse(JSON.stringify(blankRoleData))
    }

    const onSubmit = id => {
      store.dispatch('roles/updateRole', {
        roleData: roleData.value,
        id,
      })
        .then(() => {
          emit('refetch-data')
          toast({
            component: ToastificationContent,
            props: {
              title: 'Role updated successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          Object.values(error.response.data)
            .forEach(message => {
              toast({
                component: ToastificationContent,
                props: {
                  title: message[0],
                  icon: 'AlertTriangleIcon',
                  variant: 'warning',
                },
              })
            })
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetroleData)

    return {
      roleData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>
