import { ref } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useRoleList() {
  // Use toast
  const toast = useToast()

  const refRolesListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name' },
    { key: 'users' },
    { key: 'permissions' },
    { key: 'actions' },
  ]

  const getByRole = ref(null)
  const getPermissions = ref(null)

  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const refetchData = () => {
    refRolesListTable.value.refresh()
  }

  const fetchRoles = (ctx, callback) => {
    store
      .dispatch('roles/fetchRoles', {
        'get-by-role': getByRole,
      })
      .then(response => {
        const { data } = response.data
        callback(data)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching roles list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchPermissions = (ctx, callback) => {
    store
      .dispatch('roles/fetchPermissions', {
        'get-permissions': getPermissions,
      })
      .then(response => {
        const { data } = response.data
        if (callback) {
          callback(data)
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching permissions list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const getCurrentRoles = () => store.getters['roles/GetCurrentRoles']
  const getCurrentPermissions = () => store.getters['roles/GetCurrentPermissions']

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchRoles,
    fetchPermissions,
    getCurrentRoles,
    getCurrentPermissions,

    tableColumns,
    refRolesListTable,

    refetchData,

    getByRole,
    getPermissions,

    sortBy,
    isSortDirDesc,
  }
}
